import { Component, OnInit, Host, HostBinding, OnDestroy } from '@angular/core';
import { LoginService } from '../login.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { trigger, transition, style, animate, query, group, stagger } from '@angular/animations';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        query('.content', [
          style({
            opacity: 0,
            transform: 'scale(0)'
          })
        ]),
        query('.logout', [
          style({
            opacity: 0,
            transform: 'scale(1.1)'
          })
        ]),
        group([
          query('.content', [
            animate(
              '.15s .5s ease-out',
              style({
                transform: 'scale(1)',
                opacity: 1
              })
            )
          ]),
          query('.logout', [
            animate(
              '.5s 1.5s ease-in',
              style({
                opacity: 1,
                transform: 'scale(1)'
              })
            )
          ])
        ])
      ]),

      transition(':leave', [
        group([
          query('.logout', [
            animate(
              '.3s ease-in',
              style({
                opacity: 0,
                transform: 'scale(3.5)'
              })
            )
          ]),
          query('.content', [
            animate(
              '.25s .25s ease-in',
              style({
                opacity: 0,
                transform: 'scale(0)'
              })
            )
          ])
        ])
      ])
    ])
  ]
})
export class HomeComponent implements OnInit, OnDestroy {
  @HostBinding('@slide') ready = true;
  url = null;
  loading = false;

  constructor(
    private loginService: LoginService,
    private sanitizer: DomSanitizer,
    private router: Router
  ) {}

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.loginService.redirect);
  }

  ngOnDestroy() {
    this.loading = false;
  }

  logout() {
    this.loading = true;
    this.loginService.logged = false;
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }
}
