import { Component, OnInit, HostBinding, OnDestroy } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { LoginService } from '../login.service';
import { trigger, transition, style, animate, query, group, stagger } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [
    trigger('slide', [
      transition(':enter', [
        query('.login', [
          style({
            transform: 'scale(0)',
            opacity: 0
          })
        ]),
        query('.pop', [
          style({
            transform: 'scale(1.1)',
            opacity: 0
          })
        ]),
        group([
          query('.login', [
            animate(
              '.2s .2s ease-out',
              style({
                transform: 'scale(1)',
                opacity: 1
              })
            )
          ]),
          query('.pop', [
            stagger(50, [
              animate(
                '0.5s .5s ease',
                style({
                  opacity: 1,
                  transform: 'scale(1)'
                })
              )
            ])
          ])
        ])
      ]),

      transition(':leave', [
        group([
          query('.login', [
            animate(
              '.25s .5s ease-out',
              style({
                transform: 'scale(0)',
                opacity: 0
              })
            )
          ]),
          query('button.pop', [
            animate(
              '.5s ease',
              style({
                transform: 'scale(2)',
                opacity: 0
              })
            )
          ]),
          query('.input-holder', [
            stagger(50, [
              animate(
                '5s ease',
                style({
                  opacity: 0
                })
              )
            ])
          ])
        ])
      ])
    ])
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  @HostBinding('@slide') ready = true;
  loginForm;
  remember = false;
  wrongCredential = false;
  error = false;
  errorMessage = null;
  showPassword = false;
  loading = false;

  constructor(private formBuilder: FormBuilder, private loginService: LoginService) {
    this.loginForm = this.formBuilder.group({
      login: ['', [Validators.required, Validators.minLength(1)]],
      password: ['', [Validators.required, Validators.minLength(4)]]
    });
  }
  ngOnInit() {}

  ngOnDestroy() {
    this.loading = false;
  }

  onSumbit(loggedData) {
    loggedData.sesame_token = null;
    this.loading = true;
    this.loginService.login(loggedData, this.remember).subscribe(
      res => {
        // console.log(res);
        this.error = res.status === 500;
        if (this.error) {
          this.errorMessage = res.message;
        }
      },
      err => {
        this.loading = false;
        this.wrongCredential = err.error.error_code === 1003;
        this.error = true;
        this.errorMessage = err.error.message;
      }
    );
  }
  changeChecked() {
    this.remember = !this.remember;
  }
  showHidePassword() {
    this.showPassword = !this.showPassword;
  }
  resetError() {
    if (this.error) {
      this.error = false;
      this.wrongCredential = false;
      this.loginForm.reset();
    }
  }
}
